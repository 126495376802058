const initialState = {
  showMotivoPatio: false,
  showTokenModal: false,
  motivoPatio: '',
  observacao: '',
  attendanceTab: {},
  originAddress: {},
  createFailureTab: '',
  actualKm: '',
  tipoCarga: '',
  tipoRevisao: '',
  showTipoRevisao: false,
  estimation: {
    distance: '',
    time: '',
    timeUnit: 0,
    distanceUnit: 0,
  },
  showConclusionTab: false,
  attendances: [],
  fetchingAttendances: false,
  newAttendanceFetching: false,
  tipoServico: 'REBOQUE',
  rodasTravadas: false,
  quantidadeRodasTravadas: 0,
  blindado: false,
  showCreateSolicitation: false,
  hasSolicitationFetching: false,
  solicitation: [],
  attendancesUploaded: {
    actualUploads: [],
    isFetching: false,
  },
  validateInfo: {
    chassi: '',
    dataFinalGarantia: '',
    premium: false,
    concessionaria: '',
    email: '',
    contato: '',
    telefoneContato: '',
    contato2: '',
    telefoneContato2: '',
    contato3: '',
    telefoneContato3: '',
  },
  favoriteDestinations: [],
};

import {
  FAILURE_DESCRIPTION_CHANGED,
  ATTENDENCES_CHANGED,
  FETCHING_ATTENDANCES,
  LOAD_ATTENDANCE_TAB,
  CLEAR_ATTENDANCE_TAB,
  ATTENDANCES_HISTORY_CHANGED,
  ATTENDANCE_TYPE_SERVICE_CHANGED,
  ATTENDANCE_VEHICLE_STATE_CHANGED,
  ATTENDANCE_NEW_SOLICITATION_VISIBILITY,
  ATTENDANCE_SOLICITATION_LIST_FETCHING,
  ATTENDANCE_SOLICITATION_LIST_CHANGED,
  NEW_ATTENDANCE_FETCHING,
  ATTENDANCE_ACTUAL_KM_CHANGED,
  ATTENDANCE_ESTIMATION_DISTANCE_CHANGED,
  ATTENDANCE_ESTIMATION_TIME_CHANGED,
  ATTENDANCE_CHARGE_TYPE_CHANGED,
  ATTENDANCE_ORIGIN_ADDRESS_CHANGED,
  ATTENDANCE_DOCUMENTS_FETCHED,
  ATTENDANCE_ESTIMATION_CHANGED,
  ATTENDANCE_DOCUMENTS_FETCHING,
  ATTENDANCE_VALIDATE_INFO_CHANGED,
  ATTENDANCE_VALIDATE_INFO_RESET,
  ATTENDANCE_FAVORITE_DESTINATIONS_CHANGED,
  ATTENDANCE_REVISION_TYPE_CHANGED,
  DISPLAY_ATTENDANCE_REVISION_TYPE,
  SET_CONCLUSION_TAB_VISIBILITY,
  SHOW_MOTIVO_PATIO,
  UPDATE_MOTIVO_PATIO,
  HIDE_MOTIVO_PATIO,
  SHOW_TOKEN_MODAL,
  HIDE_TOKEN_MODAL,
} from '../actions/attendence_actions';

function attendenceReducer(state = initialState, action) {
  switch (action.type) {
    case ATTENDANCE_FAVORITE_DESTINATIONS_CHANGED:
      return {
        ...state,
        favoriteDestinations: action.value,
      };
    case ATTENDANCE_DOCUMENTS_FETCHED:
      return {
        ...state,
        attendancesUploaded: {
          ...state.attendancesUploaded,
          actualUploads: action.payload,
        },
      };
    case UPDATE_MOTIVO_PATIO:
      return {...state, motivoPatio: action.payload};
    case SHOW_MOTIVO_PATIO:
      return {
        ...state,
        showMotivoPatio: true,
      };
    case HIDE_MOTIVO_PATIO:
      return {
        ...state,
        showMotivoPatio: false,
      };
    case SHOW_TOKEN_MODAL:
      return {
        ...state,
        showTokenModal: true,
      };
    case HIDE_TOKEN_MODAL:
      return {
        ...state,
        showTokenModal: false,
      };
    case ATTENDANCE_REVISION_TYPE_CHANGED:
      return {
        ...state,
        tipoRevisao: action.payload,
      };
    case DISPLAY_ATTENDANCE_REVISION_TYPE:
      return {
        ...state,
        showTipoRevisao: action.payload,
      };
    case ATTENDANCE_VALIDATE_INFO_RESET:
      return {
        ...state,
        validateInfo: initialState.validateInfo,
      };
    case ATTENDANCE_VALIDATE_INFO_CHANGED:
      return {
        ...state,
        validateInfo: action.payload,
      };
    case ATTENDANCE_DOCUMENTS_FETCHING:
      return {
        ...state,
        attendancesUploaded: {
          ...state.attendancesUploaded,
          isFetching: action.payload,
        },
      };
    case FAILURE_DESCRIPTION_CHANGED:
      return {
        ...state,
        createFailureTab: action.payload,
      };
    case SET_CONCLUSION_TAB_VISIBILITY:
      return {
        ...state,
        showConclusionTab: action.payload,
      };
    case ATTENDENCES_CHANGED:
      return {
        ...state,
        attendances: [...action.payload],
      };
    case NEW_ATTENDANCE_FETCHING:
      return {
        ...state,
        newAttendanceFetching: action.payload,
      };
    case FETCHING_ATTENDANCES:
      return {
        ...state,
        fetchingAttendances: action.payload,
      };
    case ATTENDANCE_ACTUAL_KM_CHANGED:
      return {
        ...state,
        actualKm: action.payload,
      };
    case ATTENDANCE_CHARGE_TYPE_CHANGED:
      return {
        ...state,
        tipoCarga: action.payload,
      };
    case ATTENDANCE_ORIGIN_ADDRESS_CHANGED:
      return {
        ...state,
        originAddress: action.payload,
      };
    case ATTENDANCE_ORIGIN_ADDRESS_CHANGED:
      return {
        ...state,
      };
    case ATTENDANCE_ESTIMATION_DISTANCE_CHANGED:
      return {
        ...state,
        estimation: {
          ...state.estimation,
          distance: action.payload,
        },
      };
    case ATTENDANCE_ESTIMATION_TIME_CHANGED:
      return {
        ...state,
        estimation: {
          ...state.estimation,
          time: action.payload,
        },
      };
    case ATTENDANCE_ESTIMATION_CHANGED:
      return {
        ...state,
        estimation: {
          ...state.estimation,
          [action.key]: action.value,
        },
      };
    case LOAD_ATTENDANCE_TAB:
      return {
        ...state,
        attendanceTab: action.payload,
      };
    case CLEAR_ATTENDANCE_TAB:
      return {
        ...state,
        attendanceTab: {},
      };
    case ATTENDANCE_TYPE_SERVICE_CHANGED:
      return {
        ...state,
        tipoServico: action.payload,
      };
    case ATTENDANCES_HISTORY_CHANGED:
      return {
        ...state,
        attendanceTab: {
          ...state.attendanceTab,
          historico: action.payload,
        },
      };
    case ATTENDANCE_VEHICLE_STATE_CHANGED:
      return {
        ...state,
        [action.payload.id]: action.payload.value,
      };
    case ATTENDANCE_NEW_SOLICITATION_VISIBILITY:
      return {
        ...state,
        showCreateSolicitation: action.payload,
      };
    case ATTENDANCE_SOLICITATION_LIST_FETCHING:
      return {
        ...state,
        hasSolicitationFetching: action.payload,
      };
    case ATTENDANCE_SOLICITATION_LIST_CHANGED:
      return {
        ...state,
        solicitation: action.payload,
      };
    default:
      return state;
  }
}

export default attendenceReducer;
