export const UPDATE_BASE = 'UPDATE_BASE';

export const HISTORICO_MODO_PRIVADO = 'HISTORICOVISIBILIDADEPRIVADA';
export const HISTORICO_MODO_PUBLICO = 'HISTORICOVISIBILIDADEPUBLICA';

export const USUARIO_TIPO_REBOQUEME = 1;
export const USUARIO_TIPO_EMPRESA = 2;
export const USUARIO_TIPO_BASE = 6;

export const CODIGO_TEGMA = '2457545233412396244';
export const CODIGO_DAF = '2322661870558778503';
export const CODIGO_KOVI = '2200248530130441273';
export const CODIGO_KAVAK = '2761360929844106663';
export const CODIGO_GRINGO = '2678645024379376924';
export const CODIGO_LOCALIZA = '2824375196939457961';
export const CODIGO_LOCALIZA_PESADOS = '3347620669109048748';
export const CODIGO_JUSTOS = '3251918332755973547';
export const CODIGO_LOOVI = '2182237038147802145';
export const CODIGO_MAPFRE = '2804803952535672232';
export const CODIGO_PORTO_SEGURO = '20';

/**
 * @readonly
 * @enum{number}
 */
export const APP_PROFILES = Object.freeze({
  /**
   * Administrador Soon
   * @property {1}
   */
  Administrador: 1,
  /**
   * @property {2}
   */ CompanyManager: 2,
  /**
   * @property {3}
   */ Operador: 3,
  /**
   * @property {4}
   */ Atendente: 4,
  /**
   * @property {5}
   */ FinanceiroEmpresa: 5,
  /**
   * @property {6}
   */ FinanceiroSoon: 6,
  /**
   * Acionamento Soon
   * @property {9}
   */ Acionamento: 9,
  /**
   * Manager Atendimento Soon
   * @property {10}
   */ ManagerAcionamento: 10,
  /**
   * Manager Atendimento Soon
   * @property {11}
   */ ManagerAtendimento: 11,
  /**
   * Analista de Rede
   * @property {12}
   */ AnalistaRede: 12,
  /**
   * Supervisor de Rede
   * @property {13}
   */ SupervisorRede: 13,
  /**
   * Supervisor de Rede
   * @property {15}
   */ Monitoramento: 15,
  /**
   * Supervisor de Rede
   * @property {16}
   */ ManagerMonitoramento: 16,

  /**
   * Supervisor de Rede
   * @property {17}
   */ CompanyMonitoramento: 17,

  /**
   * Tester
   * @property {18}
   */ Tester: 18,

  /**
   * @property {19}
   */ Patio: 19,

  /**
   * @property {20}
   */ BackOffice: 20,

  /**
   * @property {21}
   */ Suporte: 21,
});

export const PROTOCOL_CHIP_VISIBLE_FOR = '2200248530130441273';

export const reportCsvHeaders = [
  {label: 'Código', key: 'codigo'},
  {label: 'Cliente', key: 'cliente.nome'},
  {label: 'Placa', key: 'veiculoCliente.placa'},
  {label: 'Valor', key: 'valorCorrida'},
  {label: 'Agendado', key: 'agendado'},
  {label: 'Serviço', key: 'tipoServico.codigo'},
  {label: 'Descrição', key: 'descricao'},
  {label: 'Data de Aceite do Chamado', key: 'dataAceite'},
  {label: 'Data de Criação do Chamado', key: 'dataCadastro'},
  {label: 'Hora de Criação do Chamado', key: 'horaCadastro'},
  {label: 'Usuário', key: 'user.nome'},
  {label: 'Pedágio', key: 'valorPedagio'},
  {label: 'Acessório', key: 'valorAcessorios'},
  {label: 'Data de Expiração', key: 'previsaoExpiracao'},
  {label: 'Hora Parada', key: 'valorHoraParada'},
  {label: 'Hora Trabalhada', key: 'valorHoraTrabalhada'},
  {label: 'Protocolo', key: 'ownId'},
  {label: 'Logradouro da Origem', key: 'enderecoOrigem.logradouro'},
  {label: 'Número da Origem', key: 'enderecoOrigem.numero'},
  {label: 'Bairro da Origem', key: 'enderecoOrigem.bairro'},
  {label: 'Cidade da Origem', key: 'enderecoOrigem.cidade'},
  {label: 'Estado da Origem', key: 'enderecoOrigem.estado'},
  {label: 'Logradouro do destino', key: 'enderecoDestino.logradouro'},
  {label: 'Número do Destino', key: 'enderecoDestino.numero'},
  {label: 'Bairro do Destino', key: 'enderecoDestino.bairro'},
  {label: 'Cidade do Destino', key: 'enderecoDestino.cidade'},
  {label: 'Estado do Destino', key: 'enderecoDestino.estado'},
  {label: 'Chegada do Parceiro', key: 'dataChegadadoParceiro'},
  {label: 'Hora da Chegada do Parceiro', key: 'horaChegadadoParceiro'},
  {label: 'Data da Conclusão', key: 'dataConcluida'},
  {label: 'Hora da Conclusão', key: 'horaConcluida'},
  {label: 'Motivo Conclusão Manual', key: 'motivoConclusaoManual.descricao'},
  {label: 'Cliente Encontrado', key: 'clienteEncontrado'},
  {label: 'Situação', key: 'status.descricao'},
  {label: 'Observação', key: 'observacao'},
  {label: 'Tipo de Assistência', key: 'tipoRequisicao'},
  {label: 'Usuário criador do atendimento', key: 'usuarioAtendimento'},
  {label: 'Departamento do usuário', key: 'usuarioAtendimentoDepartamento'},
];

//Created because of Distância até o cliente (Km), only kovi has it
export const reportCsvHeadersKovi = [
  {label: 'Código', key: 'codigo'},
  {label: 'Cliente', key: 'cliente.nome'},
  {label: 'Placa', key: 'veiculoCliente.placa'},
  {label: 'Valor', key: 'valorCorrida'},
  {label: 'Agendado', key: 'agendado'},
  {label: 'Serviço', key: 'tipoServico.codigo'},
  {label: 'Descrição', key: 'descricao'},
  {label: 'Data de Aceite do Chamado', key: 'dataAceite'},
  {label: 'Data de Criação do Chamado', key: 'dataCadastro'},
  {label: 'Hora de Criação do Chamado', key: 'horaCadastro'},
  {label: 'Usuário', key: 'usuario'},
  {label: 'Pedágio', key: 'valorPedagio'},
  {label: 'Acessório', key: 'valorAcessorios'},
  {label: 'Hora Parada', key: 'valorHoraParada'},
  {label: 'Hora Trabalhada', key: 'valorHoraTrabalhada'},
  {label: 'Protocolo', key: 'ownId'},
  {label: 'Logradouro da Origem', key: 'enderecoOrigem.logradouro'},
  {label: 'Distância até o cliente (Km)', key: 'clienteDistancia'},
  {label: 'Distância Total (Km)', key: 'distanciaTotal'},
  {label: 'Número da Origem', key: 'enderecoOrigem.numero'},
  {label: 'Bairro da Origem', key: 'enderecoOrigem.bairro'},
  {label: 'Cidade da Origem', key: 'enderecoOrigem.cidade'},
  {label: 'Logradouro do destino', key: 'enderecoDestino.logradouro'},
  {label: 'Número do Destino', key: 'enderecoDestino.numero'},
  {label: 'Bairro do Destino', key: 'enderecoDestino.bairro'},
  {label: 'Cidade do Destino', key: 'enderecoDestino.cidade'},
  {label: 'Chegada do Parceiro', key: 'dataChegadadoParceiro'},
  {label: 'Hora da Chegada do Parceiro', key: 'horaChegadadoParceiro'},
  {label: 'Data da Conclusão', key: 'dataConcluida'},
  {label: 'Hora da Conclusão', key: 'horaConcluida'},
  {label: 'Motivo Conclusão Manual', key: 'motivoConclusaoManual.descricao'},
  {label: 'Cliente Encontrado', key: 'clienteEncontrado'},
  {label: 'Situação', key: 'status.descricao'},
  {label: 'Observação', key: 'observacao'},
  {label: 'Tipo de Assistência', key: 'tipoRequisicao'},
  {label: 'Usuário criador do atendimento', key: 'usuarioAtendimento'},
  {label: 'Departamento do usuário', key: 'usuarioAtendimentoDepartamento'},
];

export const assistanceReportCsvHeaders = [
  {label: 'Protocolo', key: 'codigo'},
  {label: 'Placa', key: 'veiculo.placa'},
  {label: 'Chassi', key: 'veiculo.chassis'},
  {label: 'Data de Criação do Chamado', key: 'dataCadastro'},
  {label: 'Agendado', key: 'agendado'},
  {label: 'Estágio Final', key: 'estagio.descricao'},
  {label: 'Login Usuário', key: 'usuario.login'},
  {label: 'Requisição', key: 'requisicao'},
  {label: 'Tipo da Requisição', key: 'tipoRequisicao.descricao'},
  {label: 'Tipo de Assistência', key: 'tipoMotivo.descricao'},
  {label: 'Tipo de Defeito', key: 'tipoDefeito.descricao'},
  {label: 'Código da Peça', key: 'partNumber'},
  {label: 'Origem da Requisição', key: 'origemRequisicao.descricao'},
  {label: 'Logradouro', key: 'endereco.logradouro'},
  {label: 'Número', key: 'endereco.numero'},
  {label: 'Bairro', key: 'endereco.bairro'},
  {label: 'Cidade', key: 'endereco.cidade'},
  {label: 'Estado', key: 'endereco.estado'},
  {label: 'Cliente', key: 'cliente.nome'},
  {label: 'Marca Veiculo', key: 'veiculo.marca'},
  {label: 'Modelo Veiculo', key: 'veiculo.modelo'},
  {label: 'Ano Veiculo', key: 'veiculo.ano'},
  {label: 'Placa Veiculo', key: 'veiculo.placa'},
  {label: 'Tipo Veiculo', key: 'veiculo.tipoVeiculo'},
  {label: 'Código Veiculo', key: 'veiculo.codigo'},
  {label: 'Data Final Garantia', key: 'veiculo.dataFinalGarantia'},
  {label: 'Tipo Carga', key: 'tipoCarga'},
  {label: 'Tipo de Carga Padronizada', key: 'tipoCargaDaf.descricao'},
  {label: 'Concessionária', key: 'enderecoFavorito.descricao'},
  {label: 'Código Concessionária', key: 'enderecoFavorito.codigo'},
  {label: 'Contato Concessionária', key: 'enderecoFavorito.contato'},
  {label: 'Km Atual', key: 'kmAtual'},
  {label: 'Distancia até o cliente', key: 'distanciaAteoCliente'},
  {label: 'Tempo até o cliente', key: 'tempoAteoCliente'},
  {label: 'Tipo de reclamação', key: 'tipoReclamacao.descricao'},
];
