import {reducer as formReducer} from 'redux-form';
import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';

import window_reducer from './window_reducer';
import vehicle_reducer from './vehicle_reducer';
import timer_reducer from './timer_reducer';
import service_reducer from './service_reducer';
import relatorio_reducer from './relatorio_reducer';
import partner_service_reducer from './partner_services_reducer';
import partner_reducer from './partner_reducer';
import notification_reducer from './notification_reducer';
import login_reducer from './login_reducer';
import heatMapReducer from './heatMapReducer';
import partnersTracking from './heatMapReducer';
import form_reducer from './form_reducer';
import followup_reducer from './followup_reducer';
import financialReducer from './financialReducer';
import financeiroReducer from './financeiro_reducer';
import dashboardsReducer from './dashboardReducer';
import customer_reducer from './customer_reducer';
import companyReducer from './companyReducer';
import checkListUploadReducer from './checkListUploadReducer';
import business_reducer from './business_reducer';
import base_reducer from './base_reducer';
import autocomplete_reducer from './autocomplete_reducer';
import attendenceReducer from './attendence_reducer';
import address_reducer from './address_reducer';
import accountReducer from './accountReducer';
import balanceReducer from '../components/balance/balanceReducer';

const allReducers = combineReducers({
  login: login_reducer,
  service: service_reducer,
  customer: customer_reducer,
  vehicle: vehicle_reducer,
  routing: routerReducer,
  address: address_reducer,
  autocomplete: autocomplete_reducer,
  forms: form_reducer,
  followup: followup_reducer,
  window: window_reducer,
  timer: timer_reducer,
  notification: notification_reducer,
  base: base_reducer,
  partner: partner_reducer,
  services: partner_service_reducer,
  heatMapData: heatMapReducer,
  partnersTracking: partnersTracking,
  financeiro: financeiroReducer,
  dashboard: dashboardsReducer,
  business: business_reducer,
  balances: balanceReducer,
  account: accountReducer,
  relatorio: relatorio_reducer,
  form: formReducer,
  financial: financialReducer,
  checkList: checkListUploadReducer,
  company: companyReducer,
  attendance: attendenceReducer,
});

export default allReducers;
