import moment from 'moment';

import {getDistance} from './maps/base_maps_helper';
import {doDeleteV2, doGetV2} from './base/base_api_v2_helper';
import {doGet, doPost, doPut, doDelete} from './base/base_api_helper';
import {CODIGO_LOCALIZA} from '../reducers/constants/index';
import {toastSuccess, toastError} from '../actions/toast_actions.js';
const dateNow = moment().format('YYYY-MM-DD HH:mm'); //.subtract(0, 'days')
const dateFrom = moment().subtract(2, 'days').format('YYYY-MM-DD HH:mm');

const tiposVeiculos = ['AUTOMOVEL', 'MOTO', 'SUV', 'VAN'];

export function cancelAttendenceSolicitation(body) {
  return doDeleteV2(`solicitation-integration`, body)
    .then(() => {
      toastSuccess('Cancelado com sucesso!');
    })
    .catch((error) => {
      toastError(error);
    });
}

export function sendSmsFindMe(clientCode) {
  return doPut(`customers/${clientCode}/findLocation`)
    .then((result) => {})
    .catch((error) => {
      console.log(error);
    });
}

export function stopPartner(solicitationId) {
  return doPut(`solicitations/${solicitationId}/stopPartnerDispatch`);
}

export function getPendingSolicitations() {
  const pendingSolutions = localStorage.getItem('pendingSolutions');

  if (pendingSolutions) {
    return JSON.parse(pendingSolutions);
  } else {
    return [];
  }
}

export function getSolicitation(solicitationCode) {
  return doGet(`solicitations/${solicitationCode}`);
}

export function getSolicitationResponse(solicitationCode) {
  return doGet(`solicitations/${solicitationCode}/response`);
}

export function monitorSolicitation(solicitationCode) {
  return doGet(`solicitations/${solicitationCode}/monitoring`);
}

export async function createSolicitation(solicitationData, auth) {
  const params = await getCreateValues(solicitationData);
  return doPost('solicitations', params, auth);
}

export async function putAlterDestination(enderecoDestino, valor, codeSolicitation) {
  return doPut(`solicitations/${codeSolicitation}/updateDestinationAddress`, {
    enderecoDestino: enderecoDestino,
    valor: valor,
  });
}

export async function getPricePrediction(request_info, auth) {
  const params = await preparePriceRequestObject(request_info);

  return doPost('solicitations/price', params, auth);
}

export async function getPriceAlterPrediction(request_info, auth) {
  const params = await preparePriceAlterRequestObject(request_info);
  return doPost('solicitations/price', params, auth);
}

export async function getAllPricePrediction(request_info, auth) {
  const params = await preparePriceRequestObject(request_info);
  return await tiposVeiculos.map(async (tipoVeiculo) => {
    return doPost('solicitations/price', {...params, tipoVeiculo}, auth)
      .then((result) => {
        return {...result, tipoVeiculo};
      })
      .then((result) => Promise.resolve(result));
  });
}

export function reopenSolicitation(solicitationCode) {
  return doPut(`solicitations/${solicitationCode}/recreate`);
}

export function sendSMSPartnerWebapp(solicitationCode, telefone, tipo = 'SMS') {
  return doPost(`solicitations/${solicitationCode}/sendPartnerWebappUrl`, {
    telefone,
    tipo,
  });
}

export function sendCHATPartnerWebapp(solicitationCode, tipo = 'CHAT') {
  return doPost(`solicitations/${solicitationCode}/sendPartnerWebappUrl`, {
    tipo,
  });
}

export function sendEMAILPartnerWebapp(solicitationCode, email, tipo = 'EMAIL') {
  return doPost(`solicitations/${solicitationCode}/sendPartnerWebappUrl`, {
    tipo,
    email,
  });
}

export function cancelSolicitation(solicitationCode, body) {
  return doPut(`solicitations/${solicitationCode}/cancel`, body);
}

export function updateResponsavelMonitoramento(solicitationCode, body) {
  return doPut(`solicitations/${solicitationCode}/updateResponsavelMonitoramento`, body);
}

export function removeResponsavelMonitoramento(solicitationCode, body) {
  return doPut(`solicitations/${solicitationCode}/removeResponsavelMonitoramento`, body);
}

export function updateResponsavelAcionamento(solicitationCode, body) {
  return doPut(`solicitations/${solicitationCode}/updateResponsavelAcionamento`, body);
}

export function removeResponsavelAcionamento(solicitationCode, body) {
  return doPut(`solicitations/${solicitationCode}/removeResponsavelAcionamento`, body);
}

export function solveSolicitation(solicitationCode, solutionCode) {
  return doPut(`solicitations/${solicitationCode}/reportSolution`, {
    codigo: solutionCode,
  });
}

export function acceptSolicitationResponse(solicitationCode, responseCode) {
  return doPut(`solicitations/${solicitationCode}/response/${responseCode}`);
}

export async function getInProgressSolicitations() {
  const inProgress = await localStorage.getItem('inProgress');
  if (inProgress) {
    return JSON.parse(inProgress);
  } else {
    return [];
  }
}

export async function getConcludedSolicitations(
  startDate,
  endDate,
  empresa,
  status,
  usuario,
  veiculo,
  parceiro,
  servicos,
  bases,
  conclusaoManual,
  codigo,
  page,
  sizePerPage,
  ownId,
  exportCsv,
  vehicleCondition,
  paymentAdvance,
  pendingInvoice,
) {
  const initialDate = startDate ? startDate.format('YYYY-MM-DD 00:00') : undefined;
  const finalDate = endDate ? endDate.format('YYYY-MM-DD 23:59') : undefined;
  veiculo = veiculo ? veiculo.trim().replace(/\s/g, '') : veiculo;
  const isChassi = veiculo && veiculo.length > 7;
  const limit = sizePerPage === 10 ? 10 : 5000;
  let url = `solicitations/documents?limit=${limit}`;

  if (codigo) {
    url += `&code=${codigo}`;
  }

  if (bases) {
    url += `&bases=${bases}`;
  }

  if (ownId) {
    url += `&ownId=${ownId}`;
  }

  if (page) {
    url += `&page=${page}`;
  }

  if (isChassi) {
    url += `&chassis=${veiculo}`;
  } else if (veiculo) {
    url += `&plate=${veiculo}`;
  }

  if (status) {
    url += `&status=${status}`;
  }

  if (empresa) {
    url += `&companies=${empresa}`;
  }

  if (servicos) {
    url += `&services=${servicos}`;
  }

  if (initialDate) {
    url += `&startDate=${initialDate}`;
  }

  if (finalDate) {
    url += `&endDate=${finalDate}`;
  }

  if (usuario) {
    url += `&users=${usuario}`;
  }

  if (parceiro) {
    url += `&partners=${parceiro}`;
  }

  if (conclusaoManual) {
    url += `&manualConclusion=${conclusaoManual}`;
  }

  if (exportCsv) {
    url += `&exportCsv=${exportCsv}`;
  }

  if (vehicleCondition) {
    url += `&vehicleConditions=${vehicleCondition}`;
  }

  if (paymentAdvance) {
    url += `&paymentAdvance=${paymentAdvance}`;
  }

  if (pendingInvoice) {
    url += `&pendingInvoice=${pendingInvoice}`;
  }

  return doGetV2(url);
}

export function getScheduledSolicitations() {
  return doGet(`solicitations/scheduled?_createdAtFrom=${dateFrom}&&_createdAtTo=${dateNow}`);
}

export function updateScheduledSolicitation(solicitationCode, dataAgendada, codePartner) {
  const params = {
    dataAgendada,
  };

  if (codePartner) {
    params.codePartner = codePartner;
  }

  return doPut(`solicitations/${solicitationCode}/updateScheduledSolicitation`, params);
}

//retorna uma lista dos guincheiros acionados para cada solicitação.
export function getCalledPartner(solicitationCode) {
  return doGet(`solicitations/${solicitationCode}/calledPartner`);
}

const getBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
      resolve(base64String.toString() || '');
    };
    reader.onerror = (error) => reject(error);
  });
};

async function getCreateValues(solicitation) {
  let agendado = false;
  let dataAgendada = null;
  let jsonFinal = {};
  let isNovaLista = 'solicitacaoEntrada' in solicitation;
  let isIdaPatio = 'empresa' in solicitation;
  let acionarManualmente = isNovaLista
    ? solicitation.solicitacaoEntrada.callPartner
    : solicitation.callPartner;
  if (solicitation.agendado && solicitation.agendado === true) {
    agendado = true;
    dataAgendada = solicitation.dataAgendada;
    jsonFinal = {
      codigoCupom: solicitation.cupom || null,
      ownId: solicitation.ownId || null,
      valorCorrida: solicitation.price,
      // distanciaClienteDestino: distance,
      cliente: {codigo: solicitation.cliente.codigo},
      veiculoCliente: {codigo: solicitation.veiculoCliente.codigo},
      tipoServico: solicitation.tipoServico,
      enderecoOrigem: solicitation.enderecoOrigem,
      enderecoDestino: solicitation.enderecoDestino,
      destinoPatio: false,
      nomeMotorista: solicitation.nomeMotorista,
      telefoneCelularMotorista: solicitation.telefoneCelularMotorista,
      ssPDF: solicitation.ssPDF
        ? {base64: await getBase64(solicitation.ssPDF), type: 'pdf'}
        : null,
      situacaoVeiculo: {
        rodasTravadas: solicitation.rodasTravadas,
        semRodas: solicitation.semRodas,
        capotado: solicitation.capotado,
        garagem: solicitation.garagem,
        blindado: solicitation.blindado,
        quantidadeRodasTravadas: solicitation.quantidadeRodasTravadas,
        semChaves: solicitation.semChaves,
      },
      observacao: solicitation.observacao,
      startCallPartner: acionarManualmente,
      sendSMS: solicitation.sendSMSCustomer || false, // startcallPartner
      agendado: agendado,
      dataAgendada: dataAgendada,
      idOrcamento: solicitation.idOrcamento,
      codigoEmpresa: solicitation.company.value,
      codigoAtendimento: solicitation.codigoAtendimento,
      motivoPatio: solicitation.motivoPatio,
      levaTraz: solicitation.levaTraz || false,
      codigoSolicitacaoLeva: solicitation.codigoSolicitacaoLeva,
      subsoloReboque: solicitation.subsoloReboque || false,
    };
  } else {
    jsonFinal = {
      agendado: false,
      levaTraz: solicitation.levaTraz || false,
      codigoSolicitacaoLeva: solicitation.codigoSolicitacaoLeva,
      codigoCupom: solicitation.cupom || null,
      ownId: isNovaLista ? solicitation.solicitacaoEntrada.ownId : solicitation.ownId || null,
      valorCorrida: isNovaLista
        ? solicitation.solicitacaoEntrada.valorCorrida
        : isIdaPatio
        ? solicitation.valorCorrida
        : solicitation.price,
      cliente: isNovaLista
        ? {codigo: solicitation.solicitacaoEntrada.cliente.codigo}
        : {codigo: solicitation.cliente.codigo},
      veiculoCliente: isNovaLista
        ? {codigo: solicitation.solicitacaoEntrada.veiculoCliente.codigo}
        : {codigo: solicitation.veiculoCliente.codigo},
      tipoServico: isNovaLista
        ? solicitation.solicitacaoEntrada.tipoServico.codigo
        : isIdaPatio
        ? solicitation.tipoServico.codigo
        : solicitation.tipoServico,
      enderecoOrigem: isNovaLista
        ? solicitation.solicitacaoEntrada.enderecoOrigem
        : solicitation.enderecoOrigem,
      enderecoDestino: isNovaLista
        ? solicitation.solicitacaoEntrada.enderecoDestino
        : solicitation.enderecoDestino,
      destinoPatio: false,
      enderecoDestinoAposPatio: solicitation.enderecoDestinoAposPatio,
      nomeMotorista: isNovaLista
        ? solicitation.solicitacaoEntrada.cliente.nome
        : solicitation.nomeMotorista, //verificar
      telefoneCelularMotorista: isNovaLista
        ? solicitation.solicitacaoEntrada.cliente.telefoneCelular
        : isIdaPatio
        ? solicitation.telefoneMotorista
        : solicitation.telefoneCelularMotorista,
      urlSsPDF: isNovaLista
        ? solicitation.solicitacaoEntrada.ssPDF
        : isIdaPatio
        ? solicitation.ssPDF
        : undefined,
      codigoAtendimento: solicitation.codigoAtendimento,
      ssPDF: isIdaPatio
        ? undefined
        : solicitation.ssPDF
        ? {base64: await getBase64(solicitation.ssPDF), type: 'pdf'}
        : undefined,
      situacaoVeiculo: isNovaLista
        ? {
            rodasTravadas: solicitation.solicitacaoEntrada.situacaoVeiculo.rodasTravadas,
            semRodas: solicitation.solicitacaoEntrada.situacaoVeiculo.semRodas,
            capotado: solicitation.solicitacaoEntrada.situacaoVeiculo.capotado,
            garagem: solicitation.solicitacaoEntrada.situacaoVeiculo.garagem,
            blindado: solicitation.solicitacaoEntrada.situacaoVeiculo.blindado,
            semChaves: solicitation.solicitacaoEntrada.situacaoVeiculo.semChaves,
            quantidadeRodasTravadas:
              solicitation.solicitacaoEntrada.situacaoVeiculo.quantidadeRodasTravadas,
          }
        : isIdaPatio
        ? {
            rodasTravadas: solicitation.situacaoVeiculo.rodasTravadas,
            semRodas: solicitation.situacaoVeiculo.semRodas,
            capotado: solicitation.situacaoVeiculo.capotado,
            garagem: solicitation.situacaoVeiculo.garagem,
            blindado: solicitation.situacaoVeiculo.blindado,
            semChaves: solicitation.situacaoVeiculo.semChaves,
            quantidadeRodasTravadas: solicitation.situacaoVeiculo.quantidadeRodasTravadas,
          }
        : {
            rodasTravadas: solicitation.rodasTravadas,
            semRodas: solicitation.semRodas,
            capotado: solicitation.capotado,
            garagem: solicitation.garagem,
            blindado: solicitation.blindado,
            semChaves: solicitation.semChaves,
            quantidadeRodasTravadas: solicitation.quantidadeRodasTravadas,
          },
      observacao: isNovaLista ? solicitation.observacao : solicitation.observacao,
      startCallPartner: acionarManualmente,
      sendSMS: solicitation.sendSMSCustomer || false,
      idOrcamento: solicitation.idOrcamento,
      codigoEmpresa: isNovaLista
        ? solicitation.solicitacaoEntrada.empresa.codigo
        : isIdaPatio
        ? solicitation.empresa.codigo
        : solicitation.company.value,
      motivoPatio: isNovaLista
        ? solicitation.solicitacaoEntrada.motivoPatio
        : solicitation.motivoPatio,
      recusa: solicitation.recusa,
      emailRecusa: solicitation.emailRecusa,
      refused: true,
      subsoloReboque: solicitation.subsoloReboque || false,
    };
  }
  if (solicitation.tipoServico === 'SUBSOLO') {
    jsonFinal.situacaoVeiculo = {
      rodasTravadas: false,
      semRodas: false,
      capotado: false,
      garagem: false,
      blindado: false,
      semChaves: false,
      quantidadeRodasTravadas: 0,
    };
  }
  if (solicitation.tipoRecusa) {
    jsonFinal.tipoRecusa = solicitation.tipoRecusa.value;
    jsonFinal.recusa = !!solicitation.tipoRecusa;
    jsonFinal.emailRecusa = solicitation.emailRecusa;
    if (solicitation.tipoRecusa.value === 'RECUSAOFICINAFECHADA') {
      jsonFinal.motivoPatio = 'Oficina Fechada. ' + solicitation.motivoPatio;
    }
    if (solicitation.tipoRecusa.value === 'RECUSAOFICINAINEXISTENTE') {
      jsonFinal.motivoPatio = 'Oficina Inexistente. ' + solicitation.motivoPatio;
    }
  }

  return {
    ...jsonFinal,
  };
}

export function callPartner(solicitationCode, partnerCode, distance, includePriority = false) {
  const data = {
    priority: includePriority,
    refused: true, // sempre será true
  };
  return doPost(
    `solicitations/${solicitationCode}/callPartner/${partnerCode}/${distance || 15000}`,
    data,
  );
}

export function sendPushNotification(codigo, mensagem) {
  return doGet(`partners/${codigo}/sendPushNotification?_msg=${mensagem}`);
}

export function getRemoveNotifyPartner(solicitationCode, codePartner) {
  return doDelete(`solicitations/${solicitationCode}/callPartner/${codePartner}`);
}

export function putUpdateDeductible(body) {
  return doPut(`solicitations/updateDeductible`, body);
}

export function acceptSolicitation(solicitationCode, codePartner) {
  return doPut(`partners/${codePartner}/acceptSolicitation/${solicitationCode}`, {});
}

export function startScheduled(solicitationCode) {
  return doPut(`solicitations/${solicitationCode}/startScheduled`);
}

export function getSendPushNotification(codePartner) {
  return doGet(`partners/${codePartner}/sendPushNotification?_msg=Chamado Pendente!`);
}

export function informArrival(solicitationCode, dateTime) {
  return doPut(`solicitations/${solicitationCode}/informArrival`, {dateTime});
}

export function updatePrevia(solicitationCode, dateTime) {
  return doPut(`solicitations/${solicitationCode}/updatePrevia`, {dateTime});
}

export function putAddExpirationTime(solicitationCode, time) {
  return doPut(`solicitations/${solicitationCode}/addExpirationTime/${time}`);
}

export function expireSolicitation(solicitationCode) {
  return doPut(`solicitations/expire/${solicitationCode}`);
}

export function restartSolicitation(solicitationCode) {
  return doPut(`solicitations/${solicitationCode}/restart`);
}

export function serviceCompleted(solicitationCode, dateTime, motivoConclusaoManual) {
  return doPut(`solicitations/${solicitationCode}/serviceCompleted`, {
    dateTime,
    motivoConclusaoManual,
  });
}

async function preparePriceRequestObject(request_info) {
  let distance;
  const distance_service = new window.google.maps.DistanceMatrixService();
  let isNovaLista = 'solicitacaoEntrada' in request_info;
  let isIdaPatio = 'empresa' in request_info;

  let enderecoOrigem = isNovaLista
    ? request_info.solicitacaoEntrada.enderecoOrigem
    : request_info.enderecoOrigem;

  let enderecoDestino = isNovaLista
    ? request_info.solicitacaoEntrada.enderecoDestino
    : request_info.enderecoDestino;

  let tipoServico = isNovaLista
    ? request_info.solicitacaoEntrada.tipoServico.codigo
    : isIdaPatio
    ? request_info.tipoServico.codigo
    : request_info.tipoServico;

  let veiculoCliente = isNovaLista
    ? request_info.solicitacaoEntrada.veiculoCliente
    : request_info.veiculoCliente;
  let enderecoDestinoAposPatio = request_info.enderecoDestinoAposPatio;
  let situacaoVeiculo = isNovaLista
    ? {
        capotado: request_info.solicitacaoEntrada.situacaoVeiculo.capotado ? true : false,
        garagem: request_info.solicitacaoEntrada.situacaoVeiculo.garagem ? true : false,
        rodasTravadas: request_info.solicitacaoEntrada.situacaoVeiculo.rodasTravadas
          ? true
          : false,
        semRodas: request_info.solicitacaoEntrada.situacaoVeiculo.semRodas ? true : false,
        blindado: request_info.solicitacaoEntrada.situacaoVeiculo.blindado ? true : false,
        semChaves: request_info.solicitacaoEntrada.situacaoVeiculo.semChaves ? true : false,
        quantidadeRodasTravadas: request_info.solicitacaoEntrada.situacaoVeiculo
          .quantidadeRodasTravadas
          ? request_info.solicitacaoEntrada.situacaoVeiculo.quantidadeRodasTravadas
          : 0,
      }
    : {
        capotado: request_info.capotado ? true : null,
        garagem: request_info.garagem ? true : null,
        rodasTravadas: request_info.rodasTravadas ? true : false,
        semRodas: request_info.semRodas ? true : null,
        blindado: request_info.blindado ? true : null,
        quantidadeRodasTravadas: request_info.quantidadeRodasTravadas
          ? request_info.quantidadeRodasTravadas
          : 0,
      };
  let codigoEmpresa = isNovaLista
    ? request_info.solicitacaoEntrada.cliente.codigoEmpresa
    : isIdaPatio
    ? request_info.empresa.codigo
    : request_info.company.value;

  const destinationCoords =
    tipoServico === 'REBOQUE'
      ? {
          latDest: enderecoDestino && enderecoDestino.latitude,
          lngDest: enderecoDestino && enderecoDestino.longitude,
        }
      : {};

  let result = {
    codigoCupom: null,
    tipoServico: tipoServico,
    enderecoDestinoAposPatio,
    tipoVeiculo: (veiculoCliente && veiculoCliente.tipoVeiculo) || 'AUTOMOVEL',
    placa: veiculoCliente && veiculoCliente.placa,
    latOrig: enderecoOrigem && enderecoOrigem.latitude,
    lngOrig: enderecoOrigem && enderecoOrigem.longitude,
    situacaoVeiculo: situacaoVeiculo,
    codigoEmpresa: codigoEmpresa,
    departureTime: request_info.dataAgendada && request_info.dataAgendada,
    ...destinationCoords,
  };

  if (request_info.distance !== undefined) {
    result.distanciaClienteDestino = request_info.distance / 1000;
  }
  if (request_info.agendado) {
    result.agendado = request_info.agendado;
  }
  if (request_info.motivoPatio) {
    result.motivoPatio = request_info.motivoPatio;
  }
  return result;
}

async function preparePriceAlterRequestObject(request_info) {
  let distance;
  const distance_service = new window.google.maps.DistanceMatrixService();

  if (request_info.tipoServico == 'REBOQUE') {
    const origin = new google.maps.LatLng(
      request_info.enderecoOrigem.latitude,
      request_info.enderecoOrigem.longitude,
    );
    const destination = new google.maps.LatLng(
      request_info.enderecoDestino.latitude,
      request_info.enderecoDestino.longitude,
    );
    // distance = await getDistance(distance_service, origin, destination)/1000
  }

  // distance = distance ? distance : 1
  const destinationCoords =
    request_info.tipoServico === 'REBOQUE'
      ? {
          latDest: request_info.enderecoDestino && request_info.enderecoDestino.latitude,
          lngDest: request_info.enderecoDestino && request_info.enderecoDestino.longitude,
        }
      : {};

  const response = {
    codigoCupom: request_info.cupom || null,
    //ownId : request_info.ownId || null,
    // distanciaClienteDestino: distance,
    tipoServico: request_info.tipoServico,
    tipoVeiculo:
      (request_info.veiculoCliente && request_info.veiculoCliente.tipoVeiculo) || 'AUTOMOVEL',
    latOrig: request_info.enderecoOrigem && request_info.enderecoOrigem.latitude,
    lngOrig: request_info.enderecoOrigem && request_info.enderecoOrigem.longitude,
    situacaoVeiculo: request_info.situacaoVeiculo,
    codigoEmpresa: request_info.company.value,
    ...destinationCoords,
  };

  if (request_info.departureTime !== null) {
    response.departureTime = request_info.departureTime;
  }

  if (request_info.atualizarPreco) {
    response.atualizarPreco = true;
    response.codigoSolicitacao = request_info.codigoSolicitacao;
  } else {
    response.atualizarPreco = false;
  }

  return response;
}
