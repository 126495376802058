import {PANEL_MAPS, PANEL_VEHICLE, PANEL_CUSTOMER} from '../views/Service';
import {
  SERVICE_CREATED,
  SERVICE_ERROR,
  SERVICE_CHANGED,
  SERVICE_FORM_UPDATED,
  SERVICE_SHOW_MAP,
  SERVICE_CUSTOMER_SELECTED,
  SERVICE_VEHICLE_SELECTED,
  CLEAR_SERVICE_STATE,
  CLEAR_SERVICE_SCHEDULE,
  CLEAR_ERROR_MESSAGE,
  SHOW_PANEL,
  SERVICE_ADDRESS_FOCUS,
  LOAD_PRICES,
  SERVICE_FETCHING,
  SHOW_MOTIVO_PATIO,
  UPDATE_MOTIVO_PATIO,
} from '../actions/service_actions';
import {SHOW_TOKEN_MODAL} from '../actions/attendence_actions';
import {MAP_ADDRESS_CHANGED, CLEAR_ADDRESS_FIELD} from '../actions/address_actions';

const initialState = {
  cliente: {},
  veiculoCliente: {},
  tipoServico: 'REBOQUE',
  situacaoVeiculo: {},
  enderecoOrigem: {},
  enderecoDestino: {},
  enderecoCliente: {},
  rightPanel: PANEL_MAPS,
  price: 0,
  priceUnderground: null,
  time: 0,
  originalPrice: 0,
  partners: [],
  carPrice: 0,
  suvPrice: 0,
  vanPrice: 0,
  avgTime: 0,
  originalCarPrice: 0,
  originalSuvPrice: 0,
  originalVanPrice: 0,
  sliderValue: 0,
  tenderList: [],
  company: null,
  callPartner: true,
  AddressAfterPatio: true,
  serviceFetching: false,
  ssPDF: null,
  showMotivoPatio: false,
  observacao: '',
  motivoPatio: '',
  tipoRecusa: null,
  emailRecusa: null,
  recusa: false,
};

const service_reducer = (state = initialState, action) => {
  switch (action.type) {
    case SERVICE_FETCHING:
      return {...state, serviceFetching: action.payload};
    case SERVICE_CREATED:
      return {
        ...state,
        errorMessage: undefined,
        customerError: undefined,
        vehicleError: undefined,
        serviceFetching: false,
      };
    case SERVICE_ERROR:
      return {...state, errorMessage: action.message, serviceFetching: false};
    case SERVICE_CHANGED:
      return {...state, tipoServico: action.value};
    case SERVICE_FORM_UPDATED:
      return {...state, [action.name]: action.value};
    case SERVICE_CUSTOMER_SELECTED:
      return {...state, cliente: action.cliente};
    case SERVICE_VEHICLE_SELECTED:
      return {...state, veiculoCliente: action.veiculo};
    case SHOW_PANEL:
      return {...state, rightPanel: action.value};
    case SERVICE_SHOW_MAP:
      return {...state, rightPanel: PANEL_MAPS};
    case SERVICE_ADDRESS_FOCUS:
      return {...state, addressFocus: !state.addressFocus};
    case MAP_ADDRESS_CHANGED:
      return {
        ...state,
        [action.field]: {
          logradouro: action.value && action.value.label,
          numero: action.value && action.value.street_number,
          bairro: action.value && action.value.area,
          cidade: action.value && action.value.city,
          estado: action.value && action.value.province,
          pais: action.value && action.value.country,
          cep: action.value && action.value.postal_code,
          latitude: action.value && action.value.location && action.value.location.lat,
          longitude: action.value && action.value.location && action.value.location.lng,
        },
      };
    case UPDATE_MOTIVO_PATIO:
      return {...state, motivoPatio: action.payload};
    case SHOW_MOTIVO_PATIO:
      return {
        ...state,
        showMotivoPatio: true,
      };
    case SHOW_TOKEN_MODAL:
      return {
        ...state,
        showTokenModal: true,
      };
    case CLEAR_ADDRESS_FIELD:
      return {...state, [action.field]: action.value};
    case CLEAR_SERVICE_STATE:
      return initialState;
    case CLEAR_SERVICE_SCHEDULE:
      const {dataAgendada, agendado, ...withoutDataAgendada} = state;
      return {...withoutDataAgendada};
    case LOAD_PRICES:
      return {
        ...state,
        price: action.price,
        priceUnderground: action.priceUnderground,
        time: action.time,
        cupomValido: action.cupomValido,
      };
    case 'SET_ORIGINAL_PRICE':
      return {...state, originalPrice: action.originalPrice};
    case CLEAR_ERROR_MESSAGE:
      return {...state, errorMessage: undefined};
    case 'MAP_CLOSEST_PARTNERS':
      return {...state, partners: action.partners || []};
    case 'SLIDER_VALUE_CHANGED':
      return {...state, sliderValue: action.sliderValue};
    case 'SET_PRICE':
      return {...state, price: action.price};
    case 'ALL_PRICES_FETCHED':
      return {
        ...state,
        carPrice: action.carPrice,
        suvPrice: action.suvPrice,
        vanPrice: action.vanPrice,
        avgTime: action.avgTime,
      };
    case 'ALL_ORIGINAL_PRICES_FETCHED':
      return {
        ...state,
        originalCarPrice: action.originalCarPrice,
        originalSuvPrice: action.originalSuvPrice,
        originalVanPrice: action.originalVanPrice,
      };
    case 'TENDER_LIST_FETCHED':
      return {...state, tenderList: action.payload};
    case 'TENDER_ID_FETCHED':
      return {...state, idOrcamento: action.payload};
    case 'SERVICE_COMPANY_CHANGED':
      return {...state, company: action.payload};
    default:
      return state;
  }
};

export default service_reducer;
