import axios from 'axios';

import {toastError, toastSuccess} from './toast_actions';
import {confirmDialog} from './confirm_actions';
import {
  attendanceTranslationMap,
  formatDateFields,
  translateObjectKeys,
  translationMap,
} from '../utils/translationUtils';
import {BASE_URL} from '../config';
import {doOpenModal} from '../components/common/CustomModal';
import {doDelete, doGet} from '../api/base/base_api_helper';
import {
  createAttendence,
  getAttendances,
  findOneAttendence,
  addAttendanceHistory,
  getAttendancesSolicitation,
  createAttendanceSolicitation,
  closeAttendence,
  getFavoriteAttendanceDestinations,
} from '../api/attendence_helper';

export const ATTENDENCES_CHANGED = 'ATTENDENCES_CHANGED';
export const FAILURE_DESCRIPTION_CHANGED = 'FAILURE_DESCRIPTION_CHANGED';
export const FETCHING_ATTENDANCES = 'FETCHING_ATTENDANCES';
export const LOAD_ATTENDANCE_TAB = 'LOAD_ATTENDANCE_TAB';
export const CLEAR_ATTENDANCE_TAB = 'CLEAR_ATTENDANCE_TAB';
export const ATTENDANCES_HISTORY_CHANGED = 'ATTENDANCES_HISTORY_CHANGED';
export const ATTENDANCE_TYPE_SERVICE_CHANGED = 'ATTENDANCE_TYPE_SERVICE_CHANGED';
export const ATTENDANCE_VEHICLE_STATE_CHANGED = 'ATTENDANCE_VEHICLE_STATE_CHANGED';
export const ATTENDANCE_NEW_SOLICITATION_VISIBILITY = 'ATTENDANCE_NEW_SOLICITATION_VISIBILITY';
export const ATTENDANCE_SOLICITATION_LIST_CHANGED = 'ATTENDANCE_SOLICITATION_LIST_CHANGED';
export const ATTENDANCE_SOLICITATION_LIST_FETCHING = 'ATTENDANCE_SOLICITATION_LIST_FETCHING';
export const NEW_ATTENDANCE_FETCHING = 'NEW_ATTENDANCE_FETCHING';
export const ATTENDANCE_ACTUAL_KM_CHANGED = 'ATTENDANCE_ACTUAL_KM_CHANGED';
export const ATTENDANCE_ESTIMATION_DISTANCE_CHANGED = 'ATTENDANCE_ESTIMATION_DISTANCE_CHANGED';
export const ATTENDANCE_ESTIMATION_TIME_CHANGED = 'ATTENDANCE_ESTIMATION_TIME_CHANGED';
export const ATTENDANCE_CHARGE_TYPE_CHANGED = 'ATTENDANCE_CHARGE_TYPE_CHANGED';
export const ATTENDANCE_ORIGIN_ADDRESS_CHANGED = 'ATTENDANCE_ORIGIN_ADDRESS_CHANGED';
export const ATTENDANCE_DOCUMENTS_FETCHED = 'ATTENDANCE_DOCUMENTS_FETCHED';
export const ATTENDANCE_DOCUMENTS_FETCHING = 'ATTENDANCE_DOCUMENTS_FETCHING';
export const ATTENDANCE_ESTIMATION_CHANGED = 'ATTENDANCE_ESTIMATION_CHANGED';
export const ATTENDANCE_VALIDATE_INFO_CHANGED = 'ATTENDANCE_VALIDATE_INFO_CHANGED';
export const ATTENDANCE_VALIDATE_INFO_RESET = 'ATTENDANCE_VALIDATE_INFO_RESET';
export const ATTENDANCE_FAVORITE_DESTINATIONS_CHANGED =
  'ATTENDANCE_FAVORITE_DESTINATIONS_CHANGED';
export const ATTENDANCE_REVISION_TYPE_CHANGED = 'ATTENDANCE_REVISION_TYPE_CHANGED';
export const DISPLAY_ATTENDANCE_REVISION_TYPE = 'DISPLAY_ATTENDANCE_REVISION_TYPE';
export const SET_CONCLUSION_TAB_VISIBILITY = 'SET_CONCLUSION_TAB_VISIBILITY';
export const SHOW_MOTIVO_PATIO = 'SHOW_MOTIVO_PATIO';
export const UPDATE_MOTIVO_PATIO = 'UPDATE_MOTIVO_PATIO';
export const HIDE_MOTIVO_PATIO = 'HIDE_MOTIVO_PATIO';
export const SHOW_TOKEN_MODAL = 'SHOW_TOKEN_MODAL';
export const HIDE_TOKEN_MODAL = 'HIDE_TOKEN_MODAL';
/**
 *
 * @param {object} payload
 */
export const setAttendanceValidateInfo = (payload) => ({
  type: ATTENDANCE_VALIDATE_INFO_CHANGED,
  payload,
});

export const setAttendanceTypeOfRevision = (payload) => ({
  type: ATTENDANCE_REVISION_TYPE_CHANGED,
  payload,
});

export const setAttendanceDisplayRevision = (payload) => ({
  type: DISPLAY_ATTENDANCE_REVISION_TYPE,
  payload,
});

export const resetAttendanceValidateInfo = () => ({
  type: ATTENDANCE_VALIDATE_INFO_RESET,
});

/**
 *
 * @param {array} payload
 */
export const setAttendanceSolicitationList = (payload) => ({
  type: ATTENDANCE_SOLICITATION_LIST_CHANGED,
  payload,
});

/**
 *
 * @param {string} payload
 */
export const setAttendanceChargeType = (payload) => ({
  type: ATTENDANCE_CHARGE_TYPE_CHANGED,
  payload,
});

/**
 *
 * @param {string} payload
 */
export const setAttendanceOriginAddress = (payload) => ({
  type: ATTENDANCE_ORIGIN_ADDRESS_CHANGED,
  payload,
});

/**
 *
 * @param {boolean} payload
 */
export const setNewAttendanceSolicitationVisibility = (payload) => ({
  type: ATTENDANCE_NEW_SOLICITATION_VISIBILITY,
  payload,
});

export const setAttendanceEstimation = (key, value) => ({
  type: ATTENDANCE_ESTIMATION_CHANGED,
  key,
  value,
});

export const setAttendanceVehicleState = (payload) => ({
  type: ATTENDANCE_VEHICLE_STATE_CHANGED,
  payload,
});

export const setAttendanceTypeService = (payload) => ({
  type: ATTENDANCE_TYPE_SERVICE_CHANGED,
  payload,
});

/**
 * @description - atualiza a descrição da falha
 * @param {string} payload
 */
export const onChangeFailureDescription = (payload) => ({
  type: FAILURE_DESCRIPTION_CHANGED,
  payload,
});

/**
 * @description - atualiza a descrição o km Atual
 * @param {string} payload
 */
export const onChangeActualKm = (payload) => ({
  type: ATTENDANCE_ACTUAL_KM_CHANGED,
  payload,
});

/**
 * @description - atualiza a distancia estimada
 * @param {string} payload
 */
export const onChangeEstimationDistance = (payload) => ({
  type: ATTENDANCE_ESTIMATION_DISTANCE_CHANGED,
  payload,
});

/**
 * @description - atualiza a descrição o km Atual
 * @param {string} payload
 */
export const onChangeEstimationTime = (payload) => ({
  type: ATTENDANCE_ESTIMATION_TIME_CHANGED,
  payload,
});
/**
 *
 * @param {array} payload
 */
export const setAttendences = (payload) => ({
  type: ATTENDENCES_CHANGED,
  payload,
});

/**
 *
 * @param {boolean} payload
 */
export const setFetchingAttendences = (payload) => ({
  type: FETCHING_ATTENDANCES,
  payload,
});

/**
 *
 * @param {object} payload
 */
export const loadAttendanceTab = (payload) => ({
  type: LOAD_ATTENDANCE_TAB,
  payload,
});

export const clearAttendanceTab = () => ({
  type: CLEAR_ATTENDANCE_TAB,
});
export function showMotivoPatio() {
  return {
    type: SHOW_MOTIVO_PATIO,
  };
}
export function hideMotivoPatio() {
  return {
    type: HIDE_MOTIVO_PATIO,
  };
}
export function showToken() {
  return {
    type: SHOW_TOKEN_MODAL,
  };
}
export function hideShowToken() {
  return {
    type: HIDE_TOKEN_MODAL,
  };
}
export function updateMotivoPatio(motivoPatio) {
  return {
    type: UPDATE_MOTIVO_PATIO,
    payload: motivoPatio,
  };
}
export function newAttendanceSolicitation(
  codigoAtendimento,
  order,
  doAfterSucess,
  motivoPatio,
) {
  return (dispatch) => {
    createAttendanceSolicitation(codigoAtendimento, order, motivoPatio)
      .then((resp) => {
        toastSuccess(resp.mensagemCliente);
        dispatch(hideMotivoPatio());
        if (doAfterSucess) doAfterSucess(resp);
      })
      .catch((err) => {
        if (err.codigoRetorno === 400 && err.campoErro === 'motivoPatio') {
          dispatch(showMotivoPatio());
        } else if (err.codigoRetorno === 403 && err.mensagemRetorno === 'Insira o Token') {
          dispatch(showToken());
          doOpenModal('CONTEXT_TOKEN_MODAL');
        } else {
          toastError(err.mensagemCliente);
        }
      });
  };
}

/**
 *  Cria um novo atendimento
 * @param {{companyCode: string, contact:{ telefone: string contato: string} }} company - Informações sobre a empresa
 * @param {{requisicao: string, origemRequisicao: string, tipoRequisicao: string}} requestInfo - Detalhes da requisição
 * @param {string} vehicle - Código do veículo
 * @param {object} address
 * @param {object} others
 * @param {function} doAfterSuccess - callback para ser usado quando a criação for bem sucedida
 */
export function newAttendence(company, requestInfo, vehicle, address, others, doAfterSuccess) {
  return (dispatch) => {
    dispatch({type: NEW_ATTENDANCE_FETCHING, payload: true});
    const {companyCode = '', contact = {}} = company;
    createAttendence(companyCode, requestInfo, contact, vehicle, address, others)
      .then((resp) => {
        if (typeof doAfterSuccess === 'function') {
          doAfterSuccess(resp);
        }
        toastSuccess(resp.mensagemCliente);
      })
      .catch((error) => {
        toastError(error.message);
        console.log(error);
      })
      .finally(() => dispatch({type: NEW_ATTENDANCE_FETCHING, payload: false}));
  };
}

/**
 * Retorna todos os atendimentos baseado nos filtros selecionados
 * @param {{
 *  _origemRequisicao: string,
 *  _tipoRequisicao: string,
 *  _createdAtFrom: string,
 *  _createdAtTo: string,
 *  _empresa: string,
 *  _status: string,
 *  _veiculo: string
 * }} filters
 */
export function filterAttendences(filters, page, limit) {
  return (dispatch) => {
    dispatch([setFetchingAttendences(true), setAttendences([])]);
    getAttendances(filters, page, limit)
      .then((resp) => {
        const data = resp.attendances.map((result) => ({
          ...translateObjectKeys(result, attendanceTranslationMap),
          totalCount: resp.totalCount,
        }));
        const formattedSolicitations = data.map((item) => formatDateFields(item));
        if (formattedSolicitations) {
          dispatch(setAttendences(formattedSolicitations));
        }
      })
      .finally(() => {
        dispatch(setFetchingAttendences(false));
      });
  };
}
/**
 *
 * @param {string} code
 */
export function fetchOneAttendance(code) {
  return (dispatch) => {
    findOneAttendence(code)
      .then((resp) => {
        if (resp.atendimento) {
          dispatch(loadAttendanceTab(resp.atendimento));
        }
      })
      .catch(console.error);
  };
}

export function createAttendanceHistory(
  codigoAtendimento,
  log,
  visibilidade,
  sendMail,
  email,
  estagio,
  adormecerAte,
  dataAgendado,
  kmAtualValidado,
  kmTotalRodadoMecanico,
) {
  return (dispatch) => {
    return addAttendanceHistory(
      codigoAtendimento,
      log,
      visibilidade,
      sendMail,
      email,
      estagio,
      adormecerAte,
      dataAgendado,
      kmAtualValidado,
      kmTotalRodadoMecanico,
    )
      .then((res) => {
        if (res.historico) {
          toastSuccess('Histórico criado com sucesso');
          /*
           Esse dispatch está lançando exceção
           entre tanto o estado no Redux está sendo alterado corretamente
           
           TODO
           Descobrir causa raíz 
           */
          dispatch({
            type: ATTENDANCES_HISTORY_CHANGED,
            payload: res.historico,
          });
        } else {
          toastError('Houve um erro ao criar o histórico');
        }
        return res;
      })
      .catch((err) => {
        if (err.mensagemCliente) {
          toastError(err.mensagemCliente);
          return err;
        }
      });
  };
}

export function findAttendanceSolicitation(codeAtendimento) {
  return (dispatch) => {
    dispatch({type: ATTENDANCE_SOLICITATION_LIST_FETCHING, payload: true});
    getAttendancesSolicitation(codeAtendimento)
      .then((resp) => {
        if (resp.listSolicitations) {
          const {listSolicitations} = resp;
          dispatch(setAttendanceSolicitationList(listSolicitations));
        } else {
          dispatch(setAttendanceSolicitationList([]));
        }
      })
      .finally(() => {
        dispatch({
          type: ATTENDANCE_SOLICITATION_LIST_FETCHING,
          payload: false,
        });
      });
  };
}

export function getPriceAndTimePrediction(request_info, doAfterSuccess) {
  return (dispatch, getState) => {
    confirmDialog(
      {
        title: `<h2>Preço: R$${request_info.price} <br/> Tempo: ${
          request_info.time
        } - ${parseInt(request_info.time * 1.3)} minutos</h2>`,
        text: `${
          request_info.kmExcedente
            ? `
            <span style="color:#F44336; font-size: 20px; font-weight: bold;">
              Essa solicitação está excedendo
              ${Math.round(request_info.kmExcedente)} Km
            </span>
            <br/>
            `
            : ''
        }
        <span style="color:#F8BB86">Ao confirmar a solicitação do serviço não será possível mais cancelar.</span> `,
        type: 'success',
        html: true,
        confirmButtonColor: '#25f4af',
        confirmButtonText: 'Confirmar',
        showLoaderOnConfirm: true,
      },
      () => {
        dispatch(
          newAttendanceSolicitation(
            request_info.codigoAtendimento,
            request_info,
            doAfterSuccess,
          ),
        );
      },
    );
  };
}

export function postAttendanceDocument(attendanceCode, files, typeDocument) {
  return (dispatch) => {
    if (!files) return;

    const promiseChain = [];
    files.forEach((f, i) => {
      let formData = new FormData();
      formData.append('image', f, f.name);
      promiseChain.push(
        axios.post(
          `${BASE_URL}atendimentos/${attendanceCode}/documents/${typeDocument}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/mixed;boundary=B0EC8D07-EBF1-4EA7-966C-E492A9F2C36E',
            },
            auth: {
              username: localStorage.getItem('username'),
              password: localStorage.getItem('password'),
            },
          },
        ),
      );
    });

    Promise.all(promiseChain)
      .then((resp) => {
        resp.forEach((item) => {
          if (!item.data) return;
          if (item.data.sucesso) {
            toastSuccess(item.data.mensagemCliente);
          } else {
            toastError(item.data.mensagemCliente);
          }
        });

        dispatch(findAttendacesDocuments(attendanceCode));
      })
      .catch((e) => {
        toastError('Erro ao enviar as imagens.');
        console.log(e);
      });
  };
}

export function findAttendacesDocuments(attendanceCode) {
  return (dispatch) => {
    dispatch({type: ATTENDANCE_DOCUMENTS_FETCHING, payload: true});
    doGet(`atendimentos/${attendanceCode}/documents`)
      .then((resp) => {
        dispatch([
          {
            type: ATTENDANCE_DOCUMENTS_FETCHED,
            payload: resp.listDocumentos ? resp.listDocumentos : [],
          },
          {
            type: ATTENDANCE_DOCUMENTS_FETCHING,
            payload: false,
          },
        ]);
      })
      .catch((e) => toastError('Um erro acessar os documentos'));
  };
}

/**
 *
 * @param {string} attendanceCode
 * @param {string} documentCode
 */
export function removeAttendanceDocument(attendanceCode, documentCode) {
  return (dispatch) => {
    doDelete(`atendimentos/${attendanceCode}/documents/${documentCode}`)
      .then((resp) => {
        toastSuccess('Imagem deletada com sucesso');
        dispatch(findAttendacesDocuments(attendanceCode));
      })
      .catch((e) => {
        toastError(e.mensagemCliente);
        console.log(e);
      });
  };
}
/**
 *
 * @param {string} attendanceCode
 * @param {{
 *  dataAgendado: string
 *  kmAtualValidado: string
 * kmTotalRodadoMecanico: string
 * }} stats
 */
export function finishAttendance(attendanceCode, log, email, doAfterSucess, rest) {
  return (dispatch) => {
    return closeAttendence(attendanceCode, log, email, rest)
      .then((resp) => {
        toastSuccess(resp.mensagemCliente);
        dispatch(fetchOneAttendance(attendanceCode));
        if (typeof doAfterSucess === 'function') doAfterSucess(resp);

        return resp;
      })
      .catch((res) => {
        const message = res.mensagemRetorno || res.mensagemCliente;
        toastError(message);

        return res;
      });
  };
}

/**
 *
 * @param {string} codigoEmpresa
 */
export function fetchFavoriteDestinationList(codigoEmpresa) {
  return (dispatch) => {
    getFavoriteAttendanceDestinations(codigoEmpresa)
      .then((results) => {
        dispatch({
          type: ATTENDANCE_FAVORITE_DESTINATIONS_CHANGED,
          value: results.listFavoriteDestinations ? results.listFavoriteDestinations : [],
        });
      })
      .catch((error) => {
        console.log(
          'Erro ao carregar os Endereços Favoritos do atendimento: ' + error.message,
        );
      });
  };
}
