import {doGetV2} from './base/base_api_v2_helper';
import {doPost, doPut} from './base/base_api_helper';

export function postLogin(username, password) {
  return doPost('users/loginAdmin', {
    username: username,
    password: password,
  });
}

export function putResetPassword(userEmail) {
  return doPut(`users/${userEmail}/resetPassword`, {});
}

export function putChangePassword(senhaAtual, novaSenha) {
  return doPut(`users/changePassword`, {
    senhaAtual: senhaAtual,
    novaSenha: novaSenha,
  });
}

export function getUsers(name) {
  return doGetV2(`users?name=${name}`);
}

export function getUsersSupervisors() {
  return doGetV2(`users/supervisors`);
}
